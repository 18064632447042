<template>
<div id="">

  <navbar></navbar>
  <v-container grid-list-xs,sm,md,lg,xl>
    <Loading v-if="initialLoad"></Loading>
    <v-layout  wrap justify-center v-else>

      <v-flex xs8>
        <v-layout  wrap>
          <v-flex >

          <h2 class="display-1">{{$t("paymentPage.mra")}}</h2>
          <div class="official-info">
            <br>My Room Abroad - 出國租
            <br>VAT: 50895192
            <br>臺北市大同區南京西路163號2樓之39

          </div>
          <h1 class="ma-5 display-2">{{$t("paymentPage.invoice")}}</h1>
        </v-flex>

          <v-spacer></v-spacer>
          <v-img aspect-ratio=1 src="https://res.cloudinary.com/my-room-abroad/image/upload/q_auto,fl_lossy,f_auto/v1537411386/homepage/Logo_square.png" alt="My Room Abroad logo" height="100%"></v-img>
        </v-layout>
        <v-layout  wrap>

          <v-flex xs4>
            <h3>{{$t("paymentPage.invoiceFor")}}</h3>
            <p>{{order.tenant.name}}</p>
            <h3>{{$t("paymentPage.dueDate")}}:</h3>
            <p>{{dueDate}}</p>
          </v-flex>
          <v-flex xs6>
            <h3>{{$t("paymentPage.payableTo")}}</h3>
            <p>
              {{$t("paymentPage.mra")}}
            </p>
            <div class="account-details" v-if="order.currency=='EUR'">
              <p>{{$t("paymentPage.accountHolder")}}: My Room Abroad</p>
              <p>IBAN: BE82 9731 8580 6968</p>
              <p>BIC: ARSPBE22</p>
            </div>
            <div class="account-details" v-else-if="order.currency=='USD'">
              <p>
                {{$t("paymentPage.accountHolder")}}: 出國租股份有限公司
                <br>Bank: CTBC China Trust Commercial Bank
                <br>Bank Code: 822
                <br>{{$t("paymentPage.accountNumber")}}: 2525 4012 8423
              </p>
            </div>
            <!-- <div class="account-details" v-else-if="order.currency=='AUD'">
              <p>
                {{$t("paymentPage.accountHolder")}}: Lime Tech SPRL
                <br>{{$t("paymentPage.accountNumber")}}: 348186424
                <br>BSB-code: 082-182
                <br>Bank: TransferWise
                <br>800 Bourke
                <br>Melbourne
                <br>3000
                <br>Australia
              </p>
            </div>

            <div class="account-details" v-else-if="order.currency=='GBP'">
              <p>
                {{$t("paymentPage.accountHolder")}}: Lime Tech SPRL
                <br>{{$t("paymentPage.accountNumber")}}: 17575882
                <br>UK-Sortcode: 23-14-70
                <br>Bank: TransferWise
                <br>56 Shoreditch High Street
                <br>London
                <br>E1 6JJ
                <br>United Kingdom
              </p>
            </div> -->
            <!-- <div class="account-details" v-else>
              <p>
                {{$t("paymentPage.accountHolder")}}: Mechele Vincent
                <br>Bank: E.SUN Bank
                <br>Bank Code 808
                <br>{{$t("paymentPage.accountNumber")}}: 0901979125244
                <br>SWIFT Code: ESUNTWTP
              </p>
            </div>
            <p>Communication: {{invoiceNumber}}</p> -->
            <div class="account-details border" v-else>
              <p>
                <div class="font-weight-bold">
                  Show this to the bank clerk:
                  <br> 我要將錢存進帳戶

                </div>
                <br>{{$t("paymentPage.accountHolder")}}: 出國租股份有限公司
                <br>Bank: Bank of Taiwan
                <br>Bank Code: 004
                <br>{{$t("paymentPage.accountNumber")}}: 019 001 163 903
              </p>
            </div>
            <p>Communication: {{invoiceNumber}}</p>

          </v-flex>
          <v-flex xs2>
            <h3>{{$t("paymentPage.invoice")}} #</h3>
            <p>{{invoiceNumber}}</p>

          </v-flex>

        </v-layout>
        <v-divider></v-divider>
        <v-layout  wrap class="ma-3 no-ma-sides">
          <v-flex xs9>
            <h2>{{$t("paymentPage.description")}}</h2>
            <br>
            <p>{{$t("paymentPage.firstRent")}}</p>
            <p v-if="utilities>0">{{$t("billing.utilities")}}</p>
            <p>{{$t("paymentPage.fee")}}</p>
          </v-flex>
          <v-flex xs3 class="text-xs-right">
            <h2>{{$t("paymentPage.amount")}}</h2>
            <br>
            <p>{{order.price}} TWD</p>
            <p v-if="utilities>0">{{utilities}} TWD</p>
            <p>{{commission}} TWD</p>
          </v-flex>
        </v-layout>
        <v-divider></v-divider>
        <v-layout  wrap class="ma-3 no-ma-sides">
          <v-flex xs9 class='text-xs-right'>
            <p v-if="discount!=0">
              {{$t("paymentPage.discount")}}
            </p>
            <p>
              {{$t("paymentPage.subtotal")}}
            </p>
            <div class="currency-change" v-if="order.currency!='TWD'">
              {{$t("paymentPage.currencyTotal")}}
            </div>
            <br>
            <h2>{{$t("paymentPage.totalToPay")}}</h2>
          </v-flex>
          <v-flex xs3 class='text-xs-right' >
            <p v-if="discount!=0">
              {{discount}} TWD
            </p>
            <p>
              {{total}} TWD
            </p>
            <div class="currency-change" v-if="order.currency!='TWD'">
              {{fxTotal}} {{order.currency}}
            </div>
            <br>
            <h2>
              <span>{{fxTotal}} {{order.currency}}</span>
            </h2>
          </v-flex>
        </v-layout>
        </v-flex>

      </v-layout>

  </v-container>
</div>
</template>
<script>
import {
  mapGetters,
} from 'vuex'
import Loading from '../shared/Loading.vue'
import axios from 'axios'
import * as types from '../../store/types'
import moment from 'moment'

export default {
  name: "",
  data: () => ({
    initialLoad:true,
    order: {},
    utilities:0,
    dueDate: "",
    apt:{},
    fxPrice: "",
    commission: '',
    discount: 0,
    total: '',
    fx: "",
    fxTotal: '',
    serviceFee: .20,
    room: {},
    transactionCost: .028,
  }),
  computed: {
    ...mapGetters({
      user: types.GET_USER,
    }),
    invoiceNumber:function(){
      let t=""
      if (this.order!={}){
        t=moment(this.order.confirmTimestamp).format('YY')+moment(this.order.confirmTimestamp).format('MM')+moment(this.order.confirmTimestamp).format('DD')+this.order.tenant.name.charAt(0)+this.order.tenant.name.split(" ").pop().charAt(0)
      }
      return t
    },

  },
  methods: {
    setUtilities(){
      let t=0
      if (!this.order.utilities){

        // if (this.order.type=='room'){
        //
        //   if (this.apt.contractDetails.utilities.toLowerCase() == "fixed monthly amount"){
        //     t= Number(this.apt.contractDetails.utilitiesAmount)
        //   }
        // } else {
        //   if (this.room.billing.utilities.toLowerCase() == "fixed monthly amount"){
        //     t= Number(this.room.billing.utilitiesAmount)
        //   }
        // }

      } else {
        t=this.order.utilities
      }
      this.utilities=t
      this.calculatePrices()
    },
    calculatePrices() {
      // console.log('calculatePrices')
      let roomPrice = new Number(this.order.price)

      this.fxPrice = Math.ceil(this.fx * roomPrice)
      // console.log(this.fxPrice)
      this.commission = Math.ceil((((roomPrice+this.utilities) * this.serviceFee) + roomPrice + this.utilities) * this.transactionCost + ((roomPrice+this.utilities) * this.serviceFee))
      this.total = roomPrice + this.commission - this.discount + this.utilities
      // console.log(this.total)
      this.fxTotal = Math.ceil(this.total * this.fx)
    },
  },
  created() {
    //do something after creating vue instance
    axios.get('completedOrders/' + this.$route.params.orderId + '.json')
      .then(res => {
        this.initialLoad=false
        console.log('got the order', res.data)
        this.order = res.data
        this.fx = res.data.fx
        let newDueDate = moment(this.order.confirmTimestamp).add(2, 'd').format('ll')
        // console.log(newDueDate)
        if (this.order.discount && this.order.discount != 0) {
          this.discount = parseInt(this.order.discount)
        }
        if (this.order.type=='room'){
          axios.get('rooms/' + this.order.listingId + '.json')
          .then(res => {
            this.initialLoad=false
            this.room = res.data
            axios.get('apartments/'+ this.room.apartmentId+'.json')
            .then(res=>{
              this.apt=res.data
              this.setUtilities()
            })
          })
          .catch(err=>{
            console.log('error getting the room:', err);
          })

        } else if (this.order.type=="studio"){
          axios.get('studios/' + this.order.listingId + '.json')
          .then(res => {
            this.initialLoad=false
            this.room = res.data
            this.setUtilities()
        })
          .catch(err=>{
            console.log('error getting the studio:', err);
          })
        } else if (this.order.type=='apartment'){
          axios.get('wholeApts/' + this.order.listingId + '.json')
          .then(res => {
            this.initialLoad=false
            this.room = res.data
            this.setUtilities()
          })
          .catch(err=>{
            console.log('error getting the wholeApt:', err);
          })

        }
        this.dueDate = moment(newDueDate).format('ll')
        this.calculatePrices()
      })
      .catch(err=>{
        console.log('error getting the order:', err)
      })
  },
  components:{
    Loading
  }
}
</script>
<style lang="scss" scoped>
</style>
